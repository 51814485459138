<template>
  <div class="report-filter-container">
    <div class="day-range-container report-filter">
      <label class="filter-label">ช่วงเวลาของข้อมูลที่แสดงผล</label>
      <div class="range-container">
        <router-link
          @click.native="$event.stopImmediatePropagation()"
          v-for="(range, index) in rangeDates"
          :to="getPageURL(range)"
          :key="index"
          :class="{'selected': isRangeDateSelected(range)}"
          class="filter no-link">
          {{range.name}}
        </router-link>
        <div class="datepicker-holder">
          <DatePicker
            :enableSingleDatePicker="'range'"
            :presentLinkFunction="getPageURL"
            :ranges="rangeDates"
            :dateRange="dateRange"
            :onChange="onSelectDateFromCalendar"
            :enableSingleDateRange="true"
            dark />
        </div>
      </div>
    </div>
    <div class="day-range-container-mobile">
      <label class="filter-label">ช่วงเวลาของข้อมูลที่แสดงผล</label>
        <DatePicker
          :enableSingleDatePicker="'range'"
          :presentLinkFunction="getPageURL"
          :customTimePeriod="rangeDates"
          :ranges="rangeDates"
          :dateRange="dateRange"
          :onChange="onSelectDateFromCalendar"
          :forcePresetLabel="true"
          :enableSingleDateRange="true"
          dark />
    </div>
    <div class="platform-container report-filter">
      <label class="filter-label">Platform ที่แสดงผล</label>
      <div class="range-container">
        <div
          @click="onCLickPlatformFilter(platform.key)"
          v-for="(platform, index) in platforms"
          :value="platform.key"
          :key="index"
          :class="`filter ${platform.key === platformFilter ? 'selected' : ''} ${platform.className}`">
          {{platform.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { generateLink } from '@/utils/generateLink';

import DatePicker from '@/components/DatePicker.vue';

export default {
  components: {
    DatePicker,
  },
  computed: {
    query() {
      return this.$route.query;
    },
    dateRange() {
      const { range, startDate, endDate } = this.$route.query;
      // const startDate = startPickedDate;
      // const endDate = endPickedDate;
      return { range, startDate, endDate };
    },
    selectedPlatforms() {
      const { platforms } = this.$route.query;
      return platforms;
    },
    platformFilter() {
      return _.get(this.query, 'platforms', null);
    },
  },
  created() {
    this.defaultRangeDate = _.head(this.rangeDates);
    this.defaultPlatforms = _.head(this.platforms);

    this.getPageURL(this.defaultRangeDate);
  },
  data() {
    return {
      selectedRange: 'last24hours',
      defaultRangeDate: null,
      defaultPlatforms: null,
      useTime: false,
      platforms: [
        { name: 'ทุก Platform', key: null, className: 'filter-all' },
        { name: 'เฉพาะ Official Platform', key: 'app,web', className: 'filter-official' },
        { name: 'เฉพาะ TrueID', key: 'trueId', className: 'filter-true-id' },
      ],
      rangeDates: [
        {
          name: 'Last 24 Hours',
          key: 'last24hours',
        },
        {
          name: 'Last 7 Days',
          key: 'last7days',
        },
        {
          name: 'Last 30 Days',
          key: 'last30days',
        },
      ],
    };
  },
  methods: {
    generateLink,
    getPlatform(platform) {
      if (platform === 'all') {
        return null;
      } if (platform === 'trueId') {
        return 'trueId';
      }
      return 'app,web';
    },
    onCLickPlatformFilter(platforms) {
      if (platforms === this.platformFilter) {
        return;
      }
      if (!platforms) {
        const query = _.omit(this.query, ['platforms']);
        this.$router.push({ query }).catch(() => {});
      } else {
        this.$router.push({
          query: {
            ...this.query,
            platforms,
          },
        }).catch(() => {});
      }
    },
    getPageURL(rangeDate) {
      const oldQuery = _.assign(_.cloneDeep(this.$route.query), {
        startDate: null,
        endDate: null,
      });
      return this.generateLink(this.$route.path, oldQuery, {
        range: rangeDate.key,
      });
    },
    getPlatformURL(platform) {
      const oldQuery = _.assign(_.cloneDeep(this.$route.query), {
        platforms: null,
        startDate: null,
        endDate: null,
      });
      return this.generateLink(this.$route.path, oldQuery, {
        platforms: this.getPlatform(platform.key),
      });
    },
    isRangeDateSelected(rangeDate) {
      if (
        !this.dateRange.range && !this.dateRange.startDate && !this.dateRange.endDate
      ) {
        return rangeDate.key === this.defaultRangeDate.key;
      }

      if (!this.dateRange.startDate && !this.dateRange.endDate) {
        return rangeDate.key === this.dateRange.range;
      }
      return this.dateRange.range === rangeDate.key;
    },
    isPlatformisSelected(platform) {
      if (this.selectedPlatforms === 'app,web' && platform.key === 'official') {
        return true;
      }
      if (this.selectedPlatforms === undefined && platform.key === 'all') {
        return true;
      }
      return platform.key === this.selectedPlatforms;
    },
    onSelectDateFromCalendar(rangeDate) {
      const newRangeDate = {
        range: null,
        startDate: rangeDate.startDate,
        endDate: rangeDate.endDate,
      };
      const query = this.generateLink(
        this.$route.path,
        this.$route.query,
        newRangeDate,
      );
      this.$router.push(query).catch(() => {});
    },
    onSelectedPlatform(platform) {
      const newPlatform = {
        platforms: this.getPlatform(platform),
      };
      const query = this.generateLink(
        this.$route.path,
        this.$route.query,
        newPlatform,
      );
      this.$router.push(query).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/page.scss';

.report-filter-container {
  .datepicker-holder {
    display: flex;
    align-items: center;
    text-align: center;
  }
  .day-range-container {
    display: flex;
    flex-direction: column;
     flex: 0 0 55%;
  }
  .day-range-container-mobile {
    display: none;
  }
  .platform-container {
    flex: 0 0 45%;
  }
}

::v-deep .dark .vue-daterange-picker {
  min-width: unset !important;
  &.active, &:hover, &:focus, &:active {
    .form-control {
      background: #4A4F59;
      border-color: $primary-bg-lighter !important;
      color: #FFF;
    }
    span {
      font-size: $font-size-base;
    }
  }
}

@media screen and (min-width: 767.98px) and (max-width: 1023.98px) {
  .report-filter-container {
    flex-direction: column;
    .range-container {
      flex: 0 0 100%;
    }
    .time-range-container {
      flex: 0 0 100%;
    }
  }
}


@media screen and (max-width: 767.98px) {
  .report-filter-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 0;
    margin-bottom: 0;
    flex-direction: column;
    .report-filter {
      position: relative;
      flex: 0 0 100%;
      width: 100%;
    }
    .day-range-container {
      display: none;
    }
    .day-range-container-mobile {
      display: flex;
      flex-direction: column;
      margin-bottom: $spacer;
      width: 100%;
    }
    .range-container {
      flex-wrap: wrap;
      .filter {
        text-align: center;
        margin: $spacer / 3;
      }
      .filter:last-child {
        margin-left: 0;
      }
    }
    .dark ::v-deep .vue-daterange-picker {
      min-width: 100% !important;
      margin-left: 0;
    }
    .date-picker-modal {
      &.dark {
        width: 270px;
      }
    }
    .time-range-container {
      flex: 0 0 100%;
      width: 100%;
    }
    .report-filter {
      margin-bottom: $spacer;
    }
    .filter-all {
      width: 48%;
      order: 1;
      margin-right: $spacer * 0.6;
    }
    .filter-true-id {
      width: 48%;
      order: 2;
      margin-right: 0;
    }
    .filter-official {
      width: 100%;
      order: 3;
    }
    .datepicker-holder {
      display: flex;
      align-items: center;
      text-align: center;
      flex: 0 0 100%;
    }
  }
}
</style>
