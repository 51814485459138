<template>
  <div class="">
    <div @click.stop="showDatetimePicker"
      class="filter"
      :class="{'selected': isFilterSelected}">
      <div class="filter-name">{{filterLabel}}</div>
      <div class="filter-name-mobile"><i class="fas fa-calendar-alt"></i></div>
    </div>
    <div v-show="canshowDatetimePicker" class="date-time-picker-backdrop">
    <div
      v-show="canshowDatetimePicker"
      class="datetime-picker">
      <div class="form-container">
        <div class="form-group">
          <label>เลือกวันที่</label>
          <div class="d-flex justify-content-start">
          <div class="picker-container">
            <select class="custom-select datetimepicker-select" v-model="currentSelectedDay">
              <option v-for="(day, dayIndex) in days" :value="day" :key="dayIndex">{{day}}</option>
            </select>
          </div>
          <div class="picker-container">
            <select class="custom-select datetimepicker-select" v-model="currentSelectedMonth">
              <option v-for="(month, monthIndex) in months_th" :value="monthIndex" :key="monthIndex">{{month}}</option>
            </select>
          </div>
          <div class="picker-container">
            <select class="custom-select datetimepicker-select" v-model="currentSelectedYear">
              <option v-for="(year, index) in years" :value="year.value" :key="index">{{year.name}}</option>
            </select>
          </div>
        </div>
      </div>
      <div :class="{'mb-2': isDatenotinRange}" class="form-group form-timepicker time-select">
        <label>เลือกเวลา</label>
          <TimePicker
            @onChange="onSelectedTime"
            :dropDirection="'up'"
            :value="selectedTime"
            enableFullWidth
            :fullwidth="'100%'"
            :hourInterval="1"
            :minuteInterval="15" />
      </div>
      <div v-if="isDatenotinRange" class="form-group form-warning">
        <span class="text-notinrange"><i class="fas fa-exclamation-triangle mr-2"></i>{{getNotinrangeText}}</span>
      </div>
      </div>
      <div class="action-container">
        <div class="form-group d-flex justify-content-end actions">
          <button type="button" @click.stop="clearPickedDate" class="btn btn-outline-secondary">ล้างข้อมูล</button>
          <button :disabled="isDatenotinRange" type="button" @click.stop="createPickedDate" class="btn btn-primary">ยืนยัน</button>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import {
  isSameDay,
  differenceInYears,
  isWithinInterval,
  sub,
} from 'date-fns';
import { transformDateToDisplay } from '@/utils/dateFormatter';
import { generateLink } from '@/utils/generateLink';

import TimePicker from '@/components/TimePicker.vue';
import ClickOutside from 'vue-click-outside';

export default {
  props: ['selectedDay', 'selectedMonth', 'selectedYear', 'selectedTime', 'canShowDatetimePicker'],
  components: {
    TimePicker,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      months_th: ['มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน', 'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'],
      currentSelectedDay: null,
      currentSelectedMonth: null,
      currentSelectedYear: null,
      canshowDatetimePicker: false,
    };
  },
  created() {
    this.currentSelectedDay = this.selectedDay;
    this.currentSelectedMonth = this.selectedMonth;
    this.currentSelectedYear = this.selectedYear;
    const self = this;

    window.addEventListener('click', (e) => {
      if (!self.$el.contains(e.target)) {
        this.hideDatetimepicker(e);
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener('click', this.window);
  },
  computed: {
    filters() {
      return {
        range: this.$route.query.range || null,
        startDate: this.$route.query.startDate || null,
        endDate: this.$route.query.endDate || null,
        stationName: this.userStationName,
        startPickedDate: this.$route.query.startPickedDate || null,
        endPickedDate: this.$route.query.endPickedDate || null,
        pickedDate: this.$route.query.pickedDate || null,
        platforms: this.$route.query.platforms || null,
      };
    },
    isFilterSelected() {
      return !_.isEmpty(this.filters.pickedDate);
    },
    days() {
      return new Date(this.selectedYear, this.selectedMonth + 1, 0).getDate();
    },
    years() {
      const yearDiff = differenceInYears(new Date(), new Date(2020, 1, 1));
      const years = [];
      for (let i = 0; i < yearDiff + 1; i += 1) {
        const yearString = i < 10 ? `202${i}` : `20${i}`;
        years.push({ value: parseInt(yearString, 0), name: yearString });
      }
      return years;
    },
    getNotinrangeText() {
      if ((this.filters.range === 'last24hours'
          || this.filters.range === null
          || this.filters.range === undefined)
          && !this.filters.startDate) {
        return 'วันที่เลือกไม่ได้อยู่ในช่วง 24 ชั่วโมงล่าสุด';
      }
      if (this.filters.range === 'last7days') {
        return 'วันที่เลือกไม่ได้อยู่ในช่วง 7 วันล่าสุด';
      }
      if (this.filters.range === 'last30days') {
        return 'วันที่เลือกไม่ได้อยู่ในช่วง 30 วันล่าสุด';
      }
      if (this.filters.startDate && this.filters.endDate) {
        const startDateString = transformDateToDisplay(this.filters.startDate);
        const endDateString = transformDateToDisplay(this.filters.endDate);
        if (isSameDay(new Date(this.filters.startDate), new Date(this.filters.endDate))) {
          return `วันที่เลือกไม่ได้อยู่ในช่วงวันที่ ${startDateString}`;
        }
        return `วันที่เลือกไม่ได้อยู่ในช่วงวันที่ ${startDateString} - ${endDateString}`;
      }
      return '';
    },
    isDatenotinRange() {
      const {
        currentSelectedYear,
        currentSelectedMonth,
        currentSelectedDay,
      } = this;
      if ((this.filters.range === 'last24hours'
          || this.filters.range === null
          || this.filters.range === undefined)
          && !this.filters.startDate) {
        const startDate = sub(new Date(), {
          days: 2,
        });
        const result = isWithinInterval(new Date(currentSelectedYear, currentSelectedMonth, currentSelectedDay),
          { start: startDate, end: new Date() });
        return !result;
      }
      if (this.filters.range === 'last7days') {
        const startDate = sub(new Date(), {
          days: 8,
        });
        const result = isWithinInterval(new Date(currentSelectedYear, currentSelectedMonth, currentSelectedDay),
          { start: startDate, end: new Date() });
        return !result;
      }
      if (this.filters.range === 'last30days') {
        const startDate = sub(new Date(), {
          days: 31,
        });
        const result = isWithinInterval(new Date(currentSelectedYear, currentSelectedMonth, currentSelectedDay),
          { start: startDate, end: new Date() });
        return !result;
      }
      if (this.filters.startDate && this.filters.endDate) {
        const startDate = sub(new Date(this.filters.startDate), {
          days: 1,
        });
        const result = isWithinInterval(new Date(currentSelectedYear, currentSelectedMonth, currentSelectedDay),
          { start: startDate, end: new Date(this.filters.endDate) });
        return !result;
      }
      return false;
    },
    filterLabel() {
      const dateString = _.get(this.filters, 'pickedDate', null);
      const date = new Date(dateString);
      return this.filters.pickedDate ? `ระบุเวลา : ข้อมูล ณ วันที่ ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} เวลา ${date.getHours()}:${date.getMinutes() === 0 ? '00' : date.getMinutes()} น.` : 'ระบุเวลา';
    },
  },
  watch: {
    canshowDatetimePicker(newValue) {
      // do stuff
      if (newValue === true) {
        document.body.className = 'date-timepicker-opened';
      } else {
        document.body.className = '';
      }
    },
  },
  methods: {
    generateLink,
    transformDateToDisplay,
    onSelectedTime(time) {
      this.$emit('onSelectedTime', time);
    },
    clearPickedDate() {
      const newRangeDate = {
        pickedDate: null,
      };
      const query = this.generateLink(
        this.$route.path,
        this.$route.query,
        newRangeDate,
      );
      this.$router.push(query).catch(() => {});

      this.hideDatetimepicker();
    },
    createPickedDate() {
      const {
        currentSelectedYear,
        currentSelectedMonth,
        currentSelectedDay,
        selectedTime,
      } = this;
      const timeString = selectedTime.split(':');
      const startPickedDate = new Date(currentSelectedYear, currentSelectedMonth, currentSelectedDay, timeString[0], timeString[1]).toISOString();

      const newRangeDate = {
        pickedDate: startPickedDate,
      };
      const query = this.generateLink(
        this.$route.path,
        this.$route.query,
        newRangeDate,
      );
      this.$router.push(query).catch(() => {});

      this.hideDatetimepicker();
    },
    toggleDateTimepicker() {
      this.$emit('onToggleDateTimePicker');
    },
    showDatetimePicker() {
      this.canshowDatetimePicker = true;
    },
    hideDatetimepicker(event) {
      const targetElement = _.get(event, 'target.className', '');
      if (targetElement && targetElement === 'time-picker-overlay') {
        // Do noting if clicked on the parent element.
        return;
      }
      // Dismiss the modal if clicked on the elsewhere
      this.canshowDatetimePicker = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

.filter-container {
  display: flex;
  position: relative;
  justify-content: flex-end;
  // margin-bottom: $spacer;
  .filter {
    cursor: pointer;
    margin: 0 $spacer / 4;
    margin-right: 0;
    padding: 0.45rem  $spacer;
    background: #FCFCFD;
    color: #656A70;
    border-radius: $border-radius-base;
    border: 1px solid transparent;
    .filter-name-mobile {
      display: none;
    }
  }
  .selected {
    color: #2EA2E5;
    border: 1px solid #2EA2E5;
    box-sizing: border-box;
    font-weight: bold;
  }
  .filter:hover {
    color: #2EA2E5;
    border: 1px solid #2EA2E5;
    border-color: #1987c7;
  }
  .filter:nth-child(2) {
    margin-right: 0;
  }
}

.datetime-picker {
  background: $white;
  position: absolute;
  top: 50px;
  right: 0px;
  width: 330px;
  border: 1px solid #e9ecef;
  z-index: 102;
  background: #FEFEFE;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius-base;
  .form-container {
    padding: $spacer;
    border-bottom: 1px solid #E2E4E7;
    .picker-container {
      margin: auto;
    }
    .picker-container:first-child {
      margin-left: 0;
    }
    .picker-container:last-child {
      margin-right: 0;
    }
    .form-timepicker {
      width: 80%;
      margin-bottom: 0;
    }
    .form-warning {
      margin-bottom: 0;
    }
  }
  .action-container {
    padding: $spacer;
    .actions {
      margin-bottom: 0;
      button {
        margin: 0 $spacer / 2 ;
      }
      .btn-primary {
        padding: 6px 40px;
        margin-right: 0;
      }
    }
  }
}

.datetimepicker-select {
  border: none;
  border-radius: $border-radius-base;
  background-color: #F2F3F4;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position-x: right;
}

.time-select {
  width: 100%;
}

.text-notinrange {
  color: #F2994A;
  font-size: 10px;
}

@media screen and (max-width: 575.98px) {
  .filter-container {
    .filter {
      padding: $spacer * 0.75 $spacer * 0.75 !important;
      .filter-name {
        display: none;
      }
      .filter-name-mobile {
        display: flex;
        line-height: 1;
        align-items: center;
        padding: 0;
      }
    }
  }

  .datetime-picker {
    position: fixed;
    width: 100vw;
    bottom: 0;
    left: 0;
    right: 0;
    top: unset;
    z-index: 9999;
  }
  .date-time-picker-backdrop {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9000;
  }
}
</style>
