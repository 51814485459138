import qs from 'qs';
import axios from '@/modules/axios';

function loadTopProgramsByStationsGraphData(filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axios.get(`/top-program-concurrent-graph${query}`);
}

function loadTopProgramsByStations(filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axios.get(`/report/top-program-all-stations${query}`);
}

function exportTopProgramsCSV(filter) {
  const query = qs.stringify(filter, { addQueryPrefix: true, skipNulls: true });
  return axios.get(`export/top-program-all-stations${query}`);
}

export {
  loadTopProgramsByStationsGraphData,
  loadTopProgramsByStations,
  exportTopProgramsCSV,
};
