<template>
  <LineChart
    :series="series"
    :categories="categories"
    @lineClicked="onSelectedDateonGraph" />
</template>

<script>
/* eslint-disable arrow-parens */

import _ from 'lodash';
import { transformDateToDisplay } from '@/utils/dateFormatter';
import { generateLink } from '@/utils/generateLink';

import LineChart from '@/components/LineChart.vue';

export default {
  props: ['series', 'categories', 'graphColor'],
  components: {
    LineChart,
  },
  computed: {
    isSeriesEmpty() {
      return _.isEmpty(this.series);
    },
    isCategoriesEmpty() {
      return _.isEmpty(this.categories);
    },
    dateRange() {
      return this.$route.query.range || null;
    },
    startDate() {
      return this.$route.query.startDate || null;
    },
    endDate() {
      return this.$route.query.endDate || null;
    },
  },
  data() {
    return {};
  },
  methods: {
    generateLink,
    transformDateToDisplay,
    getTimeString() {
      if (this.startDate && this.endDate) {
        return `${this.transformDateToDisplay(this.startDate)} - ${this.transformDateToDisplay(this.endDate)}`;
      }
      if (this.dateRange) {
        if (this.dateRange === 'last24hours') return 'Last 24 Hours';
        if (this.dateRange === 'last7days') return 'Last 7 Days';
        if (this.dateRange === 'last30days') return 'Last 30 Days';
      }
      return 'Last 24 Hours';
    },
    onSelectedDateonGraph(date) {
      const newRangeDate = {
        pickedDate: date,
      };
      const query = this.generateLink(
        this.$route.path,
        this.$route.query,
        newRangeDate,
      );
      this.$router.push(query).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

.section-body {
  max-height: 450px;
}
.apexcharts-legend {
  .apexcharts-legend-series {
    display: flex !important;
    justify-content: center !important;
    align-self: center !important;
    align-items: center !important;
  }
}

.time {
  font-size: $font-size-base * 1.25;
  color: #bdbdbd;
}
</style>
