<template>
  <div class="page compare-container">
    <header class="page-header">
      <div class="container">
        <CompareFilter />
      </div>
    </header>
    <div class="container">
      <div class="page-content">
        <EmptyMessage v-if="graphIsEmpty">
          <h1 slot="title" class="title">ไม่พบข้อมูลในช่วงเวลาดังกล่าว</h1>
        </EmptyMessage>
      </div>
      <div class="section-body">
        <PreLoaderSection
          :options="{ height: 400 }"
          v-if="state.graph.isLoading" />
        <SectionCompareGraph
          v-if="!state.graph.isLoading && !graphIsEmpty"
          :isLoading="state.graph.isLoading"
          :isError="state.graph.isError"
          :series="series"
          :categories="categories" />
      </div>
      <div class="table-container">
        <div class="filter-container">
          <div
            @click.stop="clearPickedDate"
            class="filter"
            :class="{'selected': isFilterSelected}">
            <div class="filter-name">
              แสดงทั้งหมด
            </div>
            <div class="filter-name-mobile">
              แสดงทั้งหมด
            </div>
          </div>
          <DatetimePicker
            @onToggleDateTimePicker="toggleDateTimepicker"
            @onSelectedTime="onSelectedTime"
            :selectedTime="state.selectedTime"
            :selectedDay="state.selectedDay"
            :selectedMonth="state.selectedMonth"
            :selectedYear="state.selectedYear" />
        </div>
      </div>
      <div class="section-compare-list">
        <PreLoaderSection
          :options="{ height: 400 }"
          v-if="state.list.isLoading" />
        <SectionCompareList
          v-if="!state.list.isLoading && !programsIsEmpty"
          :programs="programs" />
        <EmptyMessage v-if="!state.list.isLoading && programsIsEmpty">
          <img slot="icon" src="@/assets/image/table_icon.svg">
          <h1 slot="title" class="title">ไม่พบยอดชมรายการในช่วงเวลาดังกล่าว</h1>
        </EmptyMessage>
      </div>
    </div>
  </div>
</template>

<script>
import { loadTopProgramsByStationsGraphData, loadTopProgramsByStations } from '@/compare/api';
import { transformDateToDisplay } from '@/utils/dateFormatter';
import { generateLink } from '@/utils/generateLink';
import { getStationDisplayName } from '@/utils/stationUtils';

import _ from 'lodash';
import SectionCompareGraph from '@/compare/SectionCompareGraph.vue';
import EmptyMessage from '@/components/EmptyMessage.vue';
import CompareFilter from '@/compare/CompareFilter.vue';
import DatetimePicker from '@/components/DatetimePicker.vue';
import PreLoaderSection from '@/components/PreLoaderSection.vue';
import SectionCompareList from '@/compare/SectionCompareList.vue';
import ClickOutside from 'vue-click-outside';

export default {
  components: {
    SectionCompareList,
    CompareFilter,
    EmptyMessage,
    SectionCompareGraph,
    PreLoaderSection,
    DatetimePicker,
  },
  directives: {
    ClickOutside,
  },
  data() {
    return {
      state: {
        graph: {
          isLoading: false,
          isError: null,
          isPreloaderShowing: true,
        },
        list: {
          isLoading: false,
          isError: null,
          isPreloaderShowing: true,
        },
        selectedFilter: 'all',
        selectedTime: '12:00',
        selectedDay: new Date().getDate(),
        selectedMonth: new Date().getMonth(),
        selectedYear: new Date().getFullYear(),
        showDateTimepicker: false,
      },
      currentTime: '',
      currentDate: new Date(),
      intervalTime: null,
      series: [],
      categories: [],
      graphData: [],
      graphColor: [],
      programs: [],
      groupedStation: null,
    };
  },
  created() {
    const filter = this.filters;

    if (filter.pickedDate) {
      this.state.selectedFilter = 'spectific';

      const dateString = _.get(this.filters, 'pickedDate', null);
      const date = new Date(dateString);
      if (dateString) {
        this.setSelectedDateMonth(date);
      }
    }

    this.getGraphData(filter);
    this.getTableData(filter);
  },
  watch: {
    filters() {
      const filter = this.filters;
      const dateString = _.get(this.filters, 'pickedDate', null);
      const date = new Date(dateString);
      if (dateString) {
        this.setSelectedDateMonth(date);
      }
      if (filter.pickedDate) {
        this.state.selectedFilter = 'spectific';
      }

      this.getGraphData(filter);
      this.getTableData(filter);
    },
  },
  computed: {
    canShowDatetimePicker() {
      return this.state.selectedFilter === 'spectific' && this.state.showDateTimepicker === true;
    },
    programsIsEmpty() {
      return _.isEmpty(this.programs.datas);
    },
    graphIsEmpty() {
      return false;
    },
    filterOptions() {
      const dateString = _.get(this.filters, 'pickedDate', null);
      const date = new Date(dateString);
      return [
        { name: 'แสดงทั้งหมด', value: 'all', mobileName: 'แสดงทั้งหมด' },
        {
          name: this.filters.pickedDate ? `ระบุเวลา : ข้อมูล ณ วันที่ ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} เวลา ${date.getHours()}:${date.getMinutes() === 0 ? '00' : date.getMinutes()} น.` : 'ระบุเวลา',
          value: 'spectific',
          mobileName: '<i class="fas fa-calendar-alt"></i>',
        },
      ];
    },
    filters() {
      return {
        range: this.$route.query.range || null,
        startDate: this.$route.query.startDate || null,
        endDate: this.$route.query.endDate || null,
        stationName: this.userStationName,
        startPickedDate: this.$route.query.startPickedDate || null,
        endPickedDate: this.$route.query.endPickedDate || null,
        pickedDate: this.$route.query.pickedDate || null,
        platforms: this.$route.query.platforms || null,
      };
    },
    isFilterSelected() {
      return _.isNull(this.filters.pickedDate);
    },
  },
  methods: {
    generateLink,
    getStationDisplayName,
    transformDateToDisplay,
    async getGraphData() {
      this.state.graph.isLoading = true;

      try {
        const response = await loadTopProgramsByStationsGraphData(this.filters);

        this.graphData = response.data;
        this.intervalTime = response.data.interval * 1000;
        this.state.graph.isPreloaderShowing = false;
        this.state.graph.isLoading = false;

        this.initSeries();
        this.initCategories();
      } catch (error) {
        this.state.graph.isError = _.get(error, 'response', error);
        this.state.graph.isPreloaderShowing = false;
        this.state.graph.isLoading = false;
      }
    },
    async getTableData() {
      this.state.list.isLoading = true;

      try {
        const response = await loadTopProgramsByStations(this.filters);

        this.programs = _.get(response.data, 'topPrograms', null);
        this.state.list.isPreloaderShowing = false;
        this.state.list.isLoading = false;
      } catch (error) {
        this.state.list.isError = _.get(error, 'response', error);
        this.state.list.isPreloaderShowing = false;
        this.state.list.isLoading = false;
      }
    },
    initSeries() {
      this.graphColor = [];

      const allFirstPeriod = this.graphData.viewCounts.map(type => type.data[0]);
      const sortedTime = _.sortBy(allFirstPeriod, 'date');
      const earliestStartTime = sortedTime[0].date;

      const formatted = _.chain(this.graphData.viewCounts)
        .sortBy(source => source.stationName)
        .map((source) => {
          const fillSource = this.fillData(source.data, earliestStartTime);
          const innerData = _.map(fillSource, innerSource => innerSource.viewCount);
          // const innerData = _.map(source.data, innerSouce => innerSouce.viewCount);

          const formattedInnerData = {
            data: innerData,
            name: getStationDisplayName(_.get(source, 'stationName')),
          };
          const color = this.getChannelColor(source.stationName);

          if (color) {
            this.graphColor.push(color);
          }

          return formattedInnerData;
        })
        .value();
      this.series = formatted;

      this.state.graph.isLoading = false;
    },
    initCategories() {
      const formatted = _.chain(this.graphData.viewCounts)
        .sortBy(source => source.stationName)
        .map((source) => {
          const innerData = _.map(source.data, innerSouce => innerSouce.date);
          const formattedInnerData = {
            data: innerData,
            name: _.get(source, 'stationName'),
          };

          return formattedInnerData;
        })
        .value();
      this.categories = formatted[0].data;
    },
    getChannelColor(channel) {
      const result = _.filter(this.colors, color => channel === color.name);

      return _.get(result, '0.color', '#000');
    },
    setSelectedFilter(selected) {
      this.state.selectedFilter = selected;
      if (selected === 'spectific') {
        this.state.showDateTimepicker = true;
      } else {
        const newRangeDate = {
          pickedDate: null,
        };
        const query = this.generateLink(
          this.$route.path,
          this.$route.query,
          newRangeDate,
        );
        this.$router.push(query).catch(() => {});
      }
    },
    toggleDateTimepicker() {
      this.state.selectedFilter = 'all';
      this.state.showDateTimepicker = !this.state.showDateTimepicker;
    },
    setSelectedDateMonth(date) {
      this.state.selectedDay = date.getDate();
      this.state.selectedMonth = date.getMonth();
      this.state.selectedYear = date.getFullYear();
      if (date.getHours() < 10) {
        this.state.selectedTime = `0${date.getHours()}:00`;
      } else {
        this.state.selectedTime = `${date.getHours()}:00`;
      }
    },
    fillData(sourceData, startTime) {
      let filledData = null;

      filledData = this.checkLostDataAtMiddle(sourceData);
      filledData = this.checkLostDataAtStart(filledData, startTime);
      return filledData;
    },
    checkLostDataAtMiddle(sourceData) {
      const filledData = _.cloneDeep(sourceData);
      sourceData.forEach((period, index) => {
        const nextPeriodData = sourceData[index + 1];
        if (nextPeriodData) {
          const thisPeriodDate = new Date(period.date);
          const nextPeriodDate = new Date(nextPeriodData.date);
          const diffTime = nextPeriodDate - thisPeriodDate;
          // If difference time more than 15 minutes, Mock new data.
          if (diffTime > this.intervalTime) {
            // Number of mock data that we need to add.
            const numberOfLost = (diffTime / this.intervalTime) - 1;
            for (let i = 1; i <= numberOfLost; i += 1) {
              const time = nextPeriodDate.getTime();
              const newDate = new Date(time + (this.intervalTime * i));
              const mockUp = {
                ...period,
                date: newDate,
                viewCount: null,
              };
              // Add new data to next index.
              filledData.splice(index + 1, 0, mockUp);
            }
          }
        }
      });
      return filledData;
    },
    checkLostDataAtStart(sourceData, startTime) {
      const filledData = _.cloneDeep(sourceData);
      const firstPeriod = sourceData[0];

      const earliestStartTime = new Date(startTime);
      const firstPeriodTime = new Date(firstPeriod.date);
      // Check difference time between start of the graph and start time of this line.
      const diffTime = firstPeriodTime - earliestStartTime;

      if (diffTime > this.intervalTime) {
        // Number of mock data that we need to add.
        const numberOfLost = (diffTime / this.intervalTime);
        for (let i = 1; i <= numberOfLost; i += 1) {
          const newDate = new Date((this.intervalTime * i));
          const mockUp = {
            ...firstPeriod,
            date: newDate,
            viewCount: null,
          };
          // Add new data to next index.
          filledData.splice(i - 1, 0, mockUp);
        }
      }
      return filledData;
    },
    onSelectedTime(time) {
      this.state.selectedTime = time.displayTime;
    },
    clearPickedDate() {
      const newRangeDate = {
        pickedDate: null,
      };
      const query = this.generateLink(
        this.$route.path,
        this.$route.query,
        newRangeDate,
      );
      this.$router.push(query).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';
@import '~@/assets/scss/themes/components/page.scss';


.table-container {
  margin-top: $spacer * 2;
  margin-bottom: $spacer * 0.75;
}


.filter-container {
  display: flex;
  position: relative;
  justify-content: flex-end;
  // margin-bottom: $spacer;
  .filter {
    cursor: pointer;
    margin: 0 $spacer / 4;
    padding: 0.45rem  $spacer;
    background: #FCFCFD;
    color: #656A70;
    border-radius: $border-radius-base;
    border: 1px solid transparent;
    .filter-name-mobile {
      display: none;
    }
  }
  .selected {
    color: #2EA2E5;
    border: 1px solid #2EA2E5;
    box-sizing: border-box;
    font-weight: bold;
  }
  .filter:hover {
    color: #2EA2E5;
    border: 1px solid #2EA2E5;
    border-color: #1987c7;
  }
  .filter:nth-child(2) {
    margin-right: 0;
  }
}

@media screen and (max-width: 767.98px) {
  .filter-container {
    .filter {
      padding: $spacer * 0.75 $spacer * 0.75 !important;
      .filter-name {
        display: none;
      }
      .filter-name-mobile {
        display: flex;
        line-height: 1;
        align-items: center;
        padding: 0;
      }
    }
  }
}

@media screen and (max-width: 575.98px) {
  .filter-container {
    position: unset;
  }
}
</style>
