<template>
  <ProgramTable
    :title="getCompareListTitle()"
    :sub-title="getCompareListSubTitle()"
    :data="data"
    :header="header"
    @export="exportCSV" />
</template>

<script>
import _ from 'lodash';
import { exportTopProgramsCSV } from '@/compare/api';

import ProgramTable from '@/components/ProgramTable.vue';

export default {
  props: ['programs', 'isLoading', 'isError'],
  components: {
    ProgramTable,
  },
  data() {
    return {
      data: null,
      header: null,
      sortBy: null,
    };
  },
  created() {
    this.initData();
  },
  computed: {
    filters() {
      return {
        range: this.$route.query.range || null,
        startDate: this.$route.query.startDate || null,
        endDate: this.$route.query.endDate || null,
        stationName: this.userStationName,
        startPickedDate: this.$route.query.startPickedDate || null,
        endPickedDate: this.$route.query.endPickedDate || null,
        pickedDate: this.$route.query.pickedDate || null,
        platforms: this.$route.query.platforms || null,
      };
    },
    getTableHeader() {
      const fixedHeaders = [
        { displayName: 'Rank', name: 'rank' },
        { displayName: 'Station', name: 'station' },
        { displayName: 'Program', name: 'program' },
      ];
      const headers = [
        { displayName: 'Web', isOfficial: true },
        { displayName: 'App', isOfficial: true },
        { displayName: 'Official Platform', isOfficial: true },
        { displayName: 'TrueID', isOfficial: false },
        { displayName: 'Total', isOfficial: true },
      ];
      if (this.filters.platforms === 'app,web') {
        const result = _.filter(headers, item => item.isOfficial === true && item.displayName !== 'Total');
        return _.unionBy(fixedHeaders, result);
      }
      if (this.filters.platforms === 'trueId') {
        const result = _.filter(headers, item => item.isOfficial === false || item.displayName === 'Total');
        return _.unionBy(fixedHeaders, result);
      }
      return _.unionBy(fixedHeaders, headers);
    },
  },
  watch: {
    filter() {
      this.initData();
    },
  },
  methods: {
    initData() {
      this.data = _.get(this.programs, 'datas', []);
      this.header = _.get(this.programs, 'header', []);
      const platforms = _.get(this.filters, 'platforms', null);

      if (!platforms) {
        return;
      }
      if (platforms === 'app,web') {
        this.header = _.filter(this.header, header => header.name !== 'trueId');
      }
      if (platforms === 'trueId') {
        this.header = _.filter(this.header, header => header.name !== 'web' && header.name !== 'app' && header.name !== 'official');
      }
    },
    async exportCSV() {
      const exportData = await exportTopProgramsCSV(this.filter);
      const downloadURL = _.get(exportData, 'data.downloadURL', '#');
      window.open(downloadURL, '_blank');
    },
    getCompareListTitle() {
      const dateString = _.get(this.filters, 'pickedDate', null);
      const startDate = _.get(this.filters, 'startDate', null);
      const endDate = _.get(this.filters, 'endDate', null);
      if (dateString || (startDate && endDate)) {
        return 'รายการที่ออกอากาศ';
      }
      if (this.filters.range === 'last24hours' || this.filters.range === undefined || this.filters.range === null) {
        return 'รายการ 24 ชั่วโมงล่าสุด';
      }
      if (this.filters.range === 'last7days') {
        return 'รายการ 7 วันล่าสุด';
      }
      if (this.filters.range === 'last30days') {
        return 'รายการ 30 วันล่าสุด';
      }
      return '';
    },
    getCompareListSubTitle() {
      const dateString = _.get(this.filters, 'pickedDate', null);
      const startDate = _.get(this.filters, 'startDate', null);
      const endDate = _.get(this.filters, 'endDate', null);
      if (dateString) {
        const date = new Date(dateString);
        return `วันที่ ${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} เวลา ${date.getHours()}:${date.getMinutes() === 0 ? '00' : date.getMinutes()} น.`;
      }
      if (startDate && endDate) {
        const startDateString = new Date(startDate);
        const endDateString = new Date(endDate);
        return `วันที่ ${startDateString.getDate()}/${startDateString.getMonth() + 1}/${startDateString.getFullYear()} - วันที่ ${endDateString.getDate()}/${endDateString.getMonth() + 1}/${endDateString.getFullYear()}`;
      }
      return null;
    },
    getClass(platform) {
      if (platform === 'officialPlatform' && !this.isOfficialSelected) {
        return 'official-ccu';
      }
      if (platform === 'officialPlatform' && this.isOfficialSelected) {
        return 'official-ccu bold-text';
      }
      return 'platform-ccu';
    },
    getViewCountFromKey(key, entries) {
      const result = _.find(entries, { name: key });

      return result ? result.viewCount : '-';
    },
    sortDataBy(key) {
      let sortedData = [];
      const data = _.get(this.programs, 'datas', null);
      if (key === undefined || key === 'total') {
        sortedData = _.orderBy(data, ['totalViewCount'], ['desc']);
      } else {
        sortedData = _.orderBy(data, item => (item[key] ? item[key] : 0), ['desc']);
      }

      this.mapAndSetFormattedData(sortedData);
    },
    mapAndSetFormattedData(sortedData) {
      const entries = sortedData.map((detail, index) => detail.entries.map((data, index2) => ({
        ...data,
        viewCount: this.numberWithCommas(sortedData[index].entries[index2].viewCount),
      })));

      this.formattedData = sortedData.map(
        (detail, index) => ({
          ...detail,
          totalViewCount: this.numberWithCommas(detail.totalViewCount),
          entries: entries[index],
        }),
      );
    },
  },
};
</script>


<style lang="scss" scoped>
@import '~@/assets/scss/variables.scss';

</style>
